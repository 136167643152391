import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Aestus NumFindr';

  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      regex: ['', []],
      data: ['', []],
      output: ['', []],
    });
  }

  getMissingNumbers() {
    const pattern = this.formGroup.get('regex').value;
    const regexPattern = pattern.replace('?', '(\\d+)');
    const invoiceNumbers = this.formGroup.get('data').value.split('\n');

    let plainNumbers = [];
    let missingNumbers = [];

    let output = '';

    if (!pattern) {
      output += 'Unesi format broja računa.';
      this.formGroup.patchValue({ output: output });

      return;
    }

    if (invoiceNumbers.length <= 1) {
      output += 'Unesi brojeve računa koje imaš, svaki u svoj redak.';
      this.formGroup.patchValue({ output: output });

      return;
    }

    invoiceNumbers.forEach((element) => {
      element = element.trim();

      const number = element.match(regexPattern);

      if (number != null) {
        plainNumbers.push(+number[1]);
      }
    });

    plainNumbers = plainNumbers.sort((n1, n2) => n1 - n2);

    for (let i = 0; i < plainNumbers.length - 1; i++) {
      for (let j = plainNumbers[i]; j < plainNumbers[i + 1]; j++) {
        var temp = j + 1;
        if (temp < plainNumbers[i + 1]) {
          missingNumbers.push(temp);
        }
      }
    }

    missingNumbers.forEach((e) => {
      output += pattern.replace('?', e) + '\n';
    });

    if (plainNumbers.length === 0) {
      output += 'Niti jedan broj računa ne odgovara formatu broja računa.';
    } else {
      output += '---\nPrvi: ' + pattern.replace('?', plainNumbers[0]);
      output +=
        '\nZadnji: ' +
        pattern.replace('?', plainNumbers[plainNumbers.length - 1]);
    }

    output += '\n---\nUkupno koji nedostaju: ' + missingNumbers.length;

    this.formGroup.patchValue({ output: output });

    console.log(missingNumbers);
  }

  copy() {
    navigator.clipboard.writeText(this.formGroup.get('output').value);
  }
}
