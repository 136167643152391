<div class="container py-3">
  <form [formGroup]="formGroup">

    <header>

      <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h1 class="display-4 fw-normal">Aestus NumFindr</h1>

        <br />

        <div class="input-group input-group-lg">
          <input type="text" class="form-control" formControlName="regex" name="regex" style="text-align: center;"
            placeholder="Unesi format broja računa, npr: ?/PP1/NU1-2023">
        </div>

        <p class="fs-5 text-body-secondary">Ovdje unesi format broja računa na način da redni broj računa zamjeniš sa
          upitnikom, npr. ako je broj računa '120/PP1/NU1-2023' ono što ti trebaš unijeti je '?/PP1/NU1-2023'.
          Trenutno je moguće provjeravati samo po jednom NU/PP-u.

        </p>
      </div>
    </header>

    <main>

      <div class="row text-center">
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Brojevi računa koje imaš</h4>
            </div>
            <div class="card-body">
              <textarea formControlName="data" name="data" class="text-input" width="100%" style="
    width: 100%;
    height: 500px;
" placeholder="Ovdje zaljepi brojeve računa koje imaš, svaki u svoj red."></textarea>
              <button type="button" class="w-100 btn btn-lg btn-primary" (click)="getMissingNumbers()">Provjeri</button>

            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Brojevi računa koji nedostaju</h4>
            </div>
            <div class="card-body">
              <textarea formControlName="output" name="output" class="text-input" width="100%" style="
                width: 100%;
                height: 500px;
            " readonly placeholder="Ovdje će se prikazati brojevi računa koji nedostaju..."></textarea>
              <button type="button" class="w-100 btn btn-lg btn-outline-primary" (click)="copy()">Kopiraj</button>

            </div>
          </div>
        </div>


        <strong>Korištenje na vlastitu odgovornost. Provjeri još jednom za svaki slučaj.</strong>
      </div>




    </main>

  </form>

</div>





<!--

<div>
  <div>
    <label for="regex-input">Enter your regex:</label>
    <input formControlName="regex" name="regex">
  </div>
  <div>
    <label for="test-data">Enter your test string:</label>
    <textarea formControlName="data" name="data"></textarea>
  </div>
  <div class="input-group">
    <span class="input-group-text">With textarea</span>
    <textarea class="form-control" aria-label="With textarea"></textarea>
  </div>
</div>


<button (click)="getMissingNumbers()">get v</button>

rg:
{{ formGroup.value.pattern }}
-->


<router-outlet></router-outlet>